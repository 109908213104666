<template>
  <div class="WorkOnline_ToViewDetails">
    <div class="stu-module-header">
      <div class="stu-module-title">在线作业</div>
      <el-button @click="back" type="primary">返回</el-button>
    </div>
    <div class="content">
      <div class="content_header">
        <p class="message">
          <span>当前作业：</span>
          <span>{{ workOnline }}</span>
        </p>
        <p class="message">
          <span>当前学生：</span>
          <span>{{ studentName }}</span>
        </p>
      </div>
      <el-table :data="tableData" :height="450" v-loading="loading">
        <el-table-column label="序号" align="center" width="60">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(col, i) in columns"
          :key="i"
          :prop="col.prop"
          :label="col.label"
          :minWidth="col.width"
          align="center"
        ></el-table-column>
        <el-table-column label="状态" align="center" width="100">
          <template slot-scope="scope">
            <el-button @click="handleStatu(scope.row)" type="text">
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { SelectStuWorkRecordByChecked } from "@/libs/api/teacher/online";
export default {
  name: "workOnline_ToViewDetails",
  props: {},
  data() {
    return {
      workOnline: "测试",
      studentName: "测试",
      loading: false,
      columns: [
        { label: "提交时间", prop: "submitTime" },
        { label: "用时", prop: "useTime" },
        { label: "是否批阅", prop: "check" },
        { label: "获得分数", prop: "results" },
      ],
      tableData: [],
      total: 0,
      pageSize: 10,
      current: 1,
    };
  },
  activated() {
    this.query();
  },
  methods: {
    query() {
      const { workSetId, studentId } = this.$route.query;
      this.loading = true;
      SelectStuWorkRecordByChecked({ workSetId, studentId })
        .then((resp) => {
          this.tableData = resp.data.data.list;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    back() {
      const { workSetId } = this.$route.query;
      this.$router.push({
        path: "/teacher/work/toView",
        name: "work-toView",
        params: {
          id: workSetId,
        },
      });
    },
    init() {},
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },

    handleStatu(row) {
      //   work/toView/detail/preview
      this.$router.push("detail/preview");
    },
  },
};
</script>

<style scoped lang="less">
.WorkOnline_ToViewDetails {
  background: #fff;
  box-shadow: @shadow;
  .stu-module-header {
    padding: 20px;
    border-bottom: 1px solid @borderBottomColor;
  }
  .content {
    padding: 20px;
    .content_header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .message {
        margin-right: 30px;
      }
    }
  }
}
</style>